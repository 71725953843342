




















































import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue';
import DialogoDeEdicaoDeMotivosDeCancelamentoFiscal from '@/components/fiscal/DialogoDeEdicaoDeMotivosDeCancelamentoFiscal.vue';
import { MotivosCancelamentoFiscal, Page, Pageable } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore';
import { FindMotivoDeCancelamentoFiscalUseCase } from '@/usecases/fiscal/FindMotivoDeCancelamentoFiscalUseCase';
import { CancelToken } from 'axios';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'

@Component({
	components: {
		DataTableDeCrudPaginado,
		DialogoDeEdicaoDeMotivosDeCancelamentoFiscal,
	},
})

export default class TelaDeMotivosDeCancelamentoFiscal extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicaoDeMotivosDeCancelamentoFiscal!: DialogoDeEdicaoDeMotivosDeCancelamentoFiscal

	findMotivoFiscalUseCase = new FindMotivoDeCancelamentoFiscalUseCase()
	busca = ''
	motivos: MotivosCancelamentoFiscal[] = []
	carregando = false
	editando = false
	motivo: MotivosCancelamentoFiscal | null = null

	headers = [
		{ text: 'Motivos', value: 'motivo' },
	]

	abrirDialogoDeEditarMotivoFiscal(indice: number) {
		try {
			if(!this.temRecurso('alterar', "motivos-de-cancelamento-fiscal")) {
				throw new Error('Necessário permissão para editar')
			}
			this.editando = true
			this.motivo = this.motivos[indice]
			this.dialogoDeEdicaoDeMotivosDeCancelamentoFiscal.mostrar()
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	abrirDialogoDeCriarMotivo() {
		try {
			if(!this.temRecurso('criar', 'motivos-de-cancelamento-fiscal')) 
				throw new Error('Necessário permissão para criar')
			this.editando = true
			this.motivo = {
				id: '',
				motivo: '',
			}  as MotivosCancelamentoFiscal
			this.dialogoDeEdicaoDeMotivosDeCancelamentoFiscal.mostrar()
		} catch(error) {
			AlertModule.setError(error)
		}
	}
	
	async buscarPagina(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<MotivosCancelamentoFiscal>> {
		const pagina = await this.findMotivoFiscalUseCase.findAll({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.motivos = pagina.content
		return pagina
	}

	get temRecurso() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso
	}

	criarRegistro(motivo: MotivosCancelamentoFiscal) {
		this.motivos.push(motivo)
	}

	atualizarRegistro(motivo: MotivosCancelamentoFiscal) {
		const indice = this.motivos.findIndex( motivoExistente => motivoExistente.id === motivo.id)
		this.motivos.splice(indice, 1, motivo)
	}

	@Watch('busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}

	@Watch('carregando')
	onChangeMostrar() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}

}
